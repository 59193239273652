import WL from 'constants/whiteLabel';
import React from 'react';

const { key: wlKey } = WL;

const hiveModels = {
  S19: {
    key: 'S19',
    title: 'S19',
    devfee: '3%',
    hashrate: '165TH/s',
    fullTitle: 'S19/S19j/S19j Pro/S19 Pro',
    label: {
      text: 'New',
      bg: 'var(--hos-color-blue-070)',
    },
  },
  T19: {
    key: 'T19',
    title: 'T19',
    devfee: '3%',
    hashrate: '108TH/s',
    fullTitle: 'T19',
    label: {
      text: 'New',
      bg: 'var(--hos-color-light-green-050)',
    },
  },
  S17: {
    key: 'S17',
    title: 'S17 / Pro',
    devfee: '2.8%',
    hashrate: '84TH/s',
    fullTitle: 'S17/S17+/S17E, S17 Pro',
  },
  T17: {
    key: 'T17',
    title: 'T17',
    devfee: '2.8%',
    hashrate: '59TH/s',
    fullTitle: 'T17/T17+/T17E',
  },
  L3: {
    key: 'L3',
    title: 'L3',
    devfee: '1.8%',
    hashrate: '720MH/s',
    fullTitle: 'L3+/L3++',
  },
  S10: {
    key: 'S10',
    title: 'S10',
    devfee: '2%',
    hashrate: '23.5TH/s',
    fullTitle: 'S10 (S9 Dual)',
  },
  S9: {
    key: 'S9',
    title: 'S9',
    devfee: '2%',
    hashrate: '19.1TH/s',
    fullTitle: 'S9/S9j/S9i',
  },
  T9: {
    key: 'T9',
    title: 'T9+',
    devfee: '2%',
    hashrate: '16.1TH/s',
    fullTitle: 'T9+',
  },
};

const bigminerModels = {
  S17: {
    key: 'S17',
    title: 'S17 / Pro',
    devfee: '2.8%',
    hashrate: '84TH/s',
    fullTitle: 'S17/S17+/S17E, S17 Pro',
    models: [
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option1',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S17-S17pro-v1.2.0h_CRC_SIG-sig-2.03%40201222-S17-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'S17/S17 Pro',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option1',
            hashrate: '80.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S17-S17pro-v1.2.0h_CRC_SIG-sig-2.03%40201222-S17-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
      // {
      //   title: 'S17+',
      //   hashrate: '80.1TH/s',
      //   options: [
      //     {
      //       intl: 's17Option2',
      //       hashrate: '19.1Th/s',
      //     },
      //   ],
      //   buttons: [
      //     {
      //       title: 'Standart',
      //       titleIntl: 'downloadButton',
      //       href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/temp_s17/Antminer-S17Plus-Bigminer-latest.tar.gz',
      //     },
      //   ],
      //   improved: false,
      // },
      // {
      //   title: 'S17+',
      //   hashrate: '80.1TH/s',
      //   options: [
      //     {
      //       intl: 's17Option2',
      //       hashrate: '19.1Th/s',
      //     },
      //   ],
      //   buttons: [
      //     {
      //       title: 'Standart',
      //       titleIntl: 'downloadButton',
      //       href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/temp_s17/SD-S17%2B-Bigminer-latest.zip',
      //     },
      //   ],
      //   improved: false,
      //   sdLabel: {
      //   name: 'SD Card,
      // },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option3',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_S17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-S17E-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'S17E',
        hashrate: '80.1TH/s',
        options: [
          {
            intl: 's17Option3',
            hashrate: '77.5Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer_S17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-S17E-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
    ],
  },
  T17: {
    key: 'T17',
    title: 'T17',
    devfee: '2.8%',
    hashrate: '59TH/s',
    fullTitle: 'T17/T17+/T17E',
    models: [
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-T17-v1.2.0h_CRC_SIG-sig-2.03%40201222-T17-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'T17',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '56/59Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-T17-v1.2.0h_CRC_SIG-sig-2.03%40201222-T17-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_T17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-T17E-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'T17E',
        hashrate: '56TH/s',
        options: [
          {
            intl: 't17Option1',
            hashrate: '62.7/67.2Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer_T17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-T17E-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
    ],
  },
  // L3: {
  //   key: 'L3',
  //   title: 'L3',
  //   devfee: '1.8%',
  //   hashrate: '720MH/s',
  //   fullTitle: 'L3+/L3++',
  //   models: [
  //     {
  //       title: 'L3+',
  //       hashrate: '720MH/s',
  //       options: [
  //         {
  //           intl: 'l3Option1',
  //           hashrate: '720 MH/s',
  //         },
  //       ],
  //       buttons: [
  //         {
  //           title: 'Standard',
  //           titleIntl: 'downloadButton',
  //           href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/L3+/Antminer-L3Plus-Bigminer-latest.tar.gz'
  //         },
  //       ],
  //     },
  //     {
  //       title: 'L3++',
  //       hashrate: '720MH/s',
  //       options: [
  //         'l3Option2',
  //       ],
  //       buttons: [
  //         {
  //           title: 'Standard',
  //           titleIntl: 'downloadButton',
  //           href: 'https://download.bigminer.io/asic/custom/f2pool/201222/asic/L3+/Antminer-L3Plus-Bigminer-latest.tar.gz'
  //         },
  //       ],
  //     },
  //   ],
  // },
  S9: {
    key: 'S9',
    title: 'S9',
    devfee: '2%',
    hashrate: '19.1TH/s',
    fullTitle: 'S9/S9j/S9i',
    models: [
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'S9',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/ss',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option2',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'S9j',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option2',
            hashrate: '19.1Th/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
          },
        ],
        improved: false,
      },
      {
        title: 'S9i',
        hashrate: '16.8TH/s',
        options: [
          {
            intl: 's9Option1',
            hashrate: '19.1TH/s',
          },
        ],
        buttons: [
          {
            title: 'Standart',
            titleIntl: 'downloadButton',
            href: 'https://download.bigminer.io/asic/custom/f2pool/201222/SD-Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.zip',
          },
        ],
        improved: false,
        sdLabel: {
          name: 'SD Card content (.zip)'
        },
      },
    ],
  },
};

export const FIRMWARE_PROFILES = {
  S19: [{
    model: 'Hiveon S19',
    buttons: [
      {
        title: 'S19',
        name: 'SD Xilinx 19',
        href: 'https://download.hiveos.farm/asic/S19/SD-S19_xilinx19-Hiveon-latest.zip',
      },
      {
        title: 'S19',
        name: 'SD Xilinx 17',
        href: 'https://download.hiveos.farm/asic/S19/SD-S19_xilinx17-Hiveon-latest.zip',
      },
      {
        title: 'S19',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/S19/WEB-S19_xilinx17and19-Hiveon-latest.tar.gz',
      },
    ],
    stock: {
      hashrate: 95,
      consumption: 3250,
      efficiency: 34.2,
    },
    table: [
      {
        hashrate: 95,
        consumption: 3120,
        efficiency: 32.8
      },
      {
        hashrate: 105,
        consumption: 3560,
        efficiency: 33.9
      }, {
        hashrate: 110,
        consumption: 3880,
        efficiency: 35.3
      }, {
        hashrate: 119,
        consumption: 4250,
        efficiency: 35.7
      }, {
        hashrate: 124,
        consumption: 4700,
        efficiency: 37.9
      }, {
        hashrate: 135,
        consumption: 5100,
        efficiency: 37.8
      }, {
        hashrate: 138,
        consumption: 5720,
        efficiency: 41.4
      }
    ]
  },
    {
      model: 'Hiveon S19 Pro',
      buttons: [
        {
          title: 'S19 Pro',
          name: 'SD Xilinx 19',
          href: 'https://download.hiveos.farm/asic/S19PRO/SD-S19PRO_xilinx19-Hiveon-latest.zip',
        },
        {
          title: 'S19 Pro',
          name: 'SD Xilinx 17',
          href: 'https://download.hiveos.farm/asic/S19PRO/SD-S19PRO_xilinx17-Hiveon-latest.zip',
        },
        {
          title: 'S19 Pro',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/S19PRO/WEB-S19PRO_xilinx17and19-Hiveon-latest.tar.gz',
        },
      ],
      stock: {
        hashrate: 110,
        consumption: 3575,
        efficiency: 32.5,
      },
      table: [{
        hashrate: 110,
        consumption: 3320,
        efficiency: 30.2
      },
        {
          hashrate: 120,
          consumption: 3540,
          efficiency: 29.5
        }, {
          hashrate: 130,
          consumption: 4100,
          efficiency: 31.5
        }, {
          hashrate: 140,
          consumption: 4690,
          efficiency: 33.5
        }, {
          hashrate: 150,
          consumption: 5300,
          efficiency: 35.3
        }, {
          hashrate: 159,
          consumption: 5712,
          efficiency: 35.9
        }, {
          hashrate: 165,
          consumption: 6100,
          efficiency: 37.0
        }
      ],
    },
    {
      model: 'Hiveon S19j',
      buttons: [
        {
          title: 'S19j',
          name: 'SD Xilinx 19',
          href: 'https://download.hiveos.farm/asic/S19J/SD-S19J_xilinx19-Hiveon-latest.zip',
        },
        {
          title: 'S19j',
          name: 'SD Xilinx 17',
          href: 'https://download.hiveos.farm/asic/S19J/SD-S19J_xilinx17-Hiveon-latest.zip',
        },
        {
          title: 'S19j',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/S19J/WEB-S19J_xilinx17and19-Hiveon-latest.tar.gz',
        },
      ],
      stock: {
        hashrate: 90,
        consumption: 3100,
        efficiency: 34.4,
      },
      table: [{
        hashrate: 90,
        consumption: 2840,
        efficiency: 31.6
      }, {
        hashrate: 100,
        consumption: 3090,
        efficiency: 30.9
      }, {
        hashrate: 108,
        consumption: 3580,
        efficiency: 33.1
      }, {
        hashrate: 115,
        consumption: 3860,
        efficiency: 33.6
      }, {
        hashrate: 120,
        consumption: 4208,
        efficiency: 35.1
      }, {
        hashrate: 125,
        consumption: 4680,
        efficiency: 37.4
      }, {
        hashrate: 133,
        consumption: 5200,
        efficiency: 39.1
      }, {
        hashrate: 139,
        consumption: 6100,
        efficiency: 43.9
      }],
    },
    {
      model: 'Hiveon S19j Pro',
      buttons: [
        {
          title: 'S19j Pro',
          name: 'SD Xilinx 19',
          href: 'https://download.hiveos.farm/asic/S19JPRO/SD-S19JPRO_xilinx19-Hiveon-latest.zip',
        },
        {
          title: 'S19j Pro',
          name: 'SD Xilinx 17',
          href: 'https://download.hiveos.farm/asic/S19JPRO/SD-S19JPRO_xilinx17-Hiveon-latest.zip',
        },
        {
          title: 'S19j Pro',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/S19JPRO/WEB-S19JPRO_xilinx17and19-Hiveon-latest.tar.gz',
        },
      ],
      stock: {
        hashrate: 104,
        consumption: 3050,
        efficiency: 29.3,
      },
      table: [{
        hashrate: 92,
        consumption: 2790,
        efficiency: 30.3
      }, {
        hashrate: 96,
        consumption: 2904,
        efficiency: 30.3
      }, {
        hashrate: 100,
        consumption: 3050,
        efficiency: 30.5
      }, {
        hashrate: 105,
        consumption: 3250,
        efficiency: 31.0
      }, {
        hashrate: 111,
        consumption: 3620,
        efficiency: 32.6
      }, {
        hashrate: 114,
        consumption: 3890,
        efficiency: 34.1
      }, {
        hashrate: 126,
        consumption: 4280,
        efficiency: 34.0
      }, {
        hashrate: 129,
        consumption: 4675,
        efficiency: 36.2
      }, {
        hashrate: 138,
        consumption: 4980,
        efficiency: 36.1
      }],
    },
  ],
  T19: [{
    model: 'Hiveon T19',
    buttons: [
      {
        title: 'T19',
        name: 'SD Xilinx 19',
        href: 'https://download.hiveos.farm/asic/T19/SD-T19_xilinx19-Hiveon-latest.zip',
      },
      {
        title: 'T19',
        name: 'SD Xilinx 17',
        href: 'https://download.hiveos.farm/asic/T19/SD-T19_xilinx17-Hiveon-latest.zip',
      },
      {
        title: 'T19',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/T19/WEB-T19_xilinx17and19-Hiveon-latest.tar.gz',
      },
    ],
    stock: {
      hashrate: 84,
      consumption: 3150,
      efficiency: 37.5,
    },
    table: [{
      hashrate: 85,
      consumption: 3050,
      efficiency: 35.9
    }, {
      hashrate: 94,
      consumption: 3570,
      efficiency: 38.0
    }, {
      hashrate: 100,
      consumption: 3990,
      efficiency: 39.9
    }, {
      hashrate: 104,
      consumption: 4217,
      efficiency: 40.5
    }, {
      hashrate: 108,
      consumption: 4620,
      efficiency: 42.8
    }]
  }, ],
  S17: [{
    model: 'Hiveon S17',
    buttons: [
      {
        title: 'S17',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17-S17pro-s17p-Hiveon-latest.tar.gz',
      },
      {
        title: 'S17',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17-S17pro-Hiveon-latest.zip',
      },
    ],
    stock: {
      hashrate: 53,
      consumption: 2094,
      efficiency: 39.5,
    },
    table: [{
      hashrate: 38.7,
      consumption: 1190,
      efficiency: 30.7
    }, {
      hashrate: 41,
      consumption: 1290,
      efficiency: 31.5
    }, {
      hashrate: 43.5,
      consumption: 1390,
      efficiency: 32.0
    }, {
      hashrate: 45.9,
      consumption: 1462,
      efficiency: 31.9
    }, {
      hashrate: 48.3,
      consumption: 1535,
      efficiency: 31.8
    }, {
      hashrate: 50.8,
      consumption: 1758,
      efficiency: 34.6
    }, {
      hashrate: 53.2,
      consumption: 1917,
      efficiency: 36.0
    }, {
      hashrate: 55.6,
      consumption: 2052,
      efficiency: 36.9
    }, {
      hashrate: 58,
      consumption: 2244,
      efficiency: 38.7
    }, {
      hashrate: 60.4,
      consumption: 2390,
      efficiency: 39.6
    }, {
      hashrate: 62.8,
      consumption: 2538,
      efficiency: 40.4
    }, {
      hashrate: 65.3,
      consumption: 2686,
      efficiency: 41.1
    }, {
      hashrate: 67.2,
      consumption: 2782,
      efficiency: 41.4
    }, {
      hashrate: 70,
      consumption: 2950,
      efficiency: 42.1
    }, {
      hashrate: 72.5,
      consumption: 3170,
      efficiency: 43.7
    }, {
      hashrate: 75,
      consumption: 3275,
      efficiency: 43.7
    }, {
      hashrate: 78,
      consumption: 3437,
      efficiency: 44.1
    }, {
      hashrate: 80.1,
      consumption: 3595,
      efficiency: 44.9
    }, {
      hashrate: 82,
      consumption: 4100,
      efficiency: 50.0
    }, {
      hashrate: 84,
      consumption: 4200,
      efficiency: 50.0
    }]
  },
    {
      model: 'Hiveon S17+',
      buttons: [
        {
          title: 'S17+',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17Plus-Hiveon-latest.tar.gz',
        },
        {
          title: 'S17+',
          name: 'SD Card content (.zip)',
          href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17%2B-Hiveon-latest.zip',
        },
      ],
      stock: {
        hashrate: 73,
        consumption: 2920,
        efficiency: 40.0,
      },
      table: [{
        hashrate: 52.5,
        consumption: 1710,
        efficiency: 32.6
      }, {
        hashrate: 55.7,
        consumption: 1848,
        efficiency: 33.2
      }, {
        hashrate: 58.9,
        consumption: 1980,
        efficiency: 33.6
      }, {
        hashrate: 62.5,
        consumption: 2124,
        efficiency: 34.0
      }, {
        hashrate: 65.9,
        consumption: 2284,
        efficiency: 34.7
      }, {
        hashrate: 68.8,
        consumption: 2430,
        efficiency: 35.3
      }, {
        hashrate: 72.5,
        consumption: 2604,
        efficiency: 35.9
      }, {
        hashrate: 75.8,
        consumption: 2880,
        efficiency: 38.0
      }, {
        hashrate: 78.6,
        consumption: 3100,
        efficiency: 39.4
      }, {
        hashrate: 82.3,
        consumption: 3260,
        efficiency: 39.6
      }, {
        hashrate: 85.5,
        consumption: 3490,
        efficiency: 40.8
      }, {
        hashrate: 88.8,
        consumption: 3860,
        efficiency: 43.5
      }, {
        hashrate: 92,
        consumption: 4090,
        efficiency: 44.5
      }, {
        hashrate: 95,
        consumption: 4280,
        efficiency: 45.1
      }],
    },
    {
      model: 'Hiveon S17E',
      buttons: [
        {
          title: 'S17E',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/temp_s17/Antminer-S17E-Hiveon-latest.tar.gz',
        },
        {
          title: 'S17E',
          name: 'SD Card content (.zip)',
          href: 'https://download.hiveos.farm/asic/temp_s17/SD-S17E-Hiveon-latest.zip',
        },
      ],
      stock: {
        hashrate: 64,
        consumption: 2880,
        efficiency: 45.0,
      },
      table: [{
        hashrate: 31.6,
        consumption: 1314,
        efficiency: 41.6,
      }, {
        hashrate: 34.9,
        consumption: 1500,
        efficiency: 43.0
      }, {
        hashrate: 38.7,
        consumption: 1686,
        efficiency: 43.6
      }, {
        hashrate: 42.6,
        consumption: 1878,
        efficiency: 44.1
      }, {
        hashrate: 46.5,
        consumption: 2073,
        efficiency: 44.6
      }, {
        hashrate: 50.4,
        consumption: 2268,
        efficiency: 45.0
      }, {
        hashrate: 54.2,
        consumption: 2469,
        efficiency: 45.6
      }, {
        hashrate: 58,
        consumption: 2673,
        efficiency: 46.1
      }, {
        hashrate: 62,
        consumption: 2877,
        efficiency: 46.4
      }, {
        hashrate: 65.9,
        consumption: 3087,
        efficiency: 46.8
      }, {
        hashrate: 69.8,
        consumption: 3297,
        efficiency: 47.2
      }, {
        hashrate: 73.6,
        consumption: 3513,
        efficiency: 47.7
      }, {
        hashrate: 77.5,
        consumption: 3820,
        efficiency: 49.3
      }],

    },
  ],
  T17: [{
    model: 'Hiveon T17',
    buttons: [
      {
        title: 'T17',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17-Hiveon-latest.tar.gz',
      },
      {
        title: 'T17',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17-Hiveon-latest.zip',
      },
    ],
    stock: {
      hashrate: 40,
      consumption: 2200,
      efficiency: 55.0,
    },
    table: [{
      hashrate: 31.7,
      consumption: 1256,
      efficiency: 39.6
    },
      {
        hashrate: 33.2,
        consumption: 1334,
        efficiency: 40.2
      }, {
        hashrate: 34.7,
        consumption: 1430,
        efficiency: 41.2
      }, {
        hashrate: 36.2,
        consumption: 1516,
        efficiency: 41.9
      }, {
        hashrate: 37.8,
        consumption: 1616,
        efficiency: 42.8
      }, {
        hashrate: 39.3,
        consumption: 1712,
        efficiency: 43.6
      }, {
        hashrate: 40.8,
        consumption: 1816,
        efficiency: 44.5
      }, {
        hashrate: 42.3,
        consumption: 1916,
        efficiency: 45.3
      }, {
        hashrate: 43.8,
        consumption: 2034,
        efficiency: 46.4
      }, {
        hashrate: 45.3,
        consumption: 2156,
        efficiency: 47.6
      }, {
        hashrate: 46.8,
        consumption: 2278,
        efficiency: 48.7
      }, {
        hashrate: 48.3,
        consumption: 2404,
        efficiency: 49.8
      }, {
        hashrate: 49.8,
        consumption: 2548,
        efficiency: 51.2
      }, {
        hashrate: 51.4,
        consumption: 2684,
        efficiency: 52.2
      }, {
        hashrate: 52.9,
        consumption: 2846,
        efficiency: 53.8
      }, {
        hashrate: 54,
        consumption: 3000,
        efficiency: 55.6
      }, {
        hashrate: 56,
        consumption: 3254,
        efficiency: 58.1
      }, {
        hashrate: 57,
        consumption: 3428,
        efficiency: 60.1
      }, {
        hashrate: 59,
        consumption: 3632,
        efficiency: 61.6
      }
    ],
  },
    {
      model: 'Hiveon T17+',
      buttons: [
        {
          title: 'T17+',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17Plus-Hiveon-latest.tar.gz',
        },
        {
          title: 'T17+',
          name: 'SD Card content (.zip)',
          href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17%2B-Hiveon-latest.zip',
        },
      ],
      stock: {
        hashrate: 58,
        consumption: 2800,
        efficiency: 48.3,
      },
      table: [{
        hashrate: 44,
        consumption: 1700,
        efficiency: 38.6
      }, {
        hashrate: 48,
        consumption: 1900,
        efficiency: 39.6
      }, {
        hashrate: 51,
        consumption: 2100,
        efficiency: 41.2
      }, {
        hashrate: 53,
        consumption: 2230,
        efficiency: 42.1
      }, {
        hashrate: 55,
        consumption: 2352,
        efficiency: 42.8
      }, {
        hashrate: 57,
        consumption: 2484,
        efficiency: 43.6
      }, {
        hashrate: 59,
        consumption: 2622,
        efficiency: 44.4
      }, {
        hashrate: 62,
        consumption: 2769,
        efficiency: 44.7
      }, {
        hashrate: 64,
        consumption: 2922,
        efficiency: 45.7
      }, {
        hashrate: 67,
        consumption: 3081,
        efficiency: 46.0
      }, {
        hashrate: 68,
        consumption: 3249,
        efficiency: 47.8
      }, {
        hashrate: 70,
        consumption: 3423,
        efficiency: 48.9
      }, {
        hashrate: 73,
        consumption: 3603,
        efficiency: 49.4
      }, {
        hashrate: 75,
        consumption: 3789,
        efficiency: 50.5
      }, ]
    },
    {
      model: 'Hiveon T17E',
      buttons: [
        {
          title: 'T17E',
          name: 'Web upgrade package (.tar.gz)',
          href: 'https://download.hiveos.farm/asic/temp_t17/Antminer-T17E-Hiveon-latest.tar.gz',
        },
        {
          title: 'T17E',
          name: 'SD Card content (.zip)',
          href: 'https://download.hiveos.farm/asic/temp_t17/SD-T17E-Hiveon-latest.zip',
        },
      ],
      stock: {
        hashrate: 53,
        consumption: 2915,
        efficiency: 55.0,
      },
      table: [{
        hashrate: 33.6,
        consumption: 1840,
        efficiency: 54.8
      }, {
        hashrate: 36.3,
        consumption: 2106,
        efficiency: 58.0
      }, {
        hashrate: 38,
        consumption: 2230,
        efficiency: 58.7
      }, {
        hashrate: 40.3,
        consumption: 2370,
        efficiency: 58.8
      }, {
        hashrate: 42.5,
        consumption: 2470,
        efficiency: 58.1
      }, {
        hashrate: 44.8,
        consumption: 2572,
        efficiency: 57.4
      }, {
        hashrate: 47,
        consumption: 2672,
        efficiency: 56.9
      }, {
        hashrate: 49.2,
        consumption: 2770,
        efficiency: 56.3
      }, {
        hashrate: 51.5,
        consumption: 2848,
        efficiency: 55.3
      }, {
        hashrate: 53.7,
        consumption: 2962,
        efficiency: 55.2
      }, {
        hashrate: 56,
        consumption: 3114,
        efficiency: 55.6
      }, {
        hashrate: 58.2,
        consumption: 3230,
        efficiency: 55.5
      }, {
        hashrate: 60.4,
        consumption: 3450,
        efficiency: 57.1
      }, {
        hashrate: 62.7,
        consumption: 3704,
        efficiency: 59.1
      }, {
        hashrate: 64.9,
        consumption: 3860,
        efficiency: 59.5
      }, {
        hashrate: 67.2,
        consumption: 4114,
        efficiency: 61.2
      }]
    },
  ],
  S10: [{
    model: 'Hiveon S10 (S9 dual)',
    buttons: [
      {
        title: 'S10',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/S10/Antminer-S10-all-Hiveon.tar.gz',
      },
      {
        title: 'S10',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/S10/SD-S10-Hiveon-latest.zip',
      },
    ],
    stock: {
      hashrate: 16.5,
      consumption: 1225,
      efficiency: 74.2,
    },
    table: [{
      hashrate: 14.4,
      consumption: 1080,
      efficiency: 75.0
    },
      {
        hashrate: 16.5,
        consumption: 1225,
        efficiency: 74.2
      }, {
        hashrate: 18.4,
        consumption: 1340,
        efficiency: 72.8
      }, {
        hashrate: 20.4,
        consumption: 1500,
        efficiency: 73.5
      }, {
        hashrate: 21.5,
        consumption: 1580,
        efficiency: 73.5
      }, {
        hashrate: 22.5,
        consumption: 1680,
        efficiency: 74.7
      }, {
        hashrate: 23.5,
        consumption: 1780,
        efficiency: 75.7
      }
    ],
  }, ],
  S9: [{
    model: 'Hiveon S9',
    buttons: [
      {
        title: 'S9',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/s9/Antminer-S9-all-Hiveon.tar.gz',
      },
      {
        title: 'S9',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/s9/SD-S9-Hiveon-latest.zip',
      },
    ],
    stock: {
      hashrate: 14.5,
      consumption: 1350,
      efficiency: 93.1,
    },
    table: [{
      hashrate: 8.8,
      consumption: 680,
      efficiency: 77.3
    }, {
      hashrate: 9.8,
      consumption: 770,
      efficiency: 78.6,
    }, {
      hashrate: 10.7,
      consumption: 870,
      efficiency: 81.3,
    }, {
      hashrate: 11.8,
      consumption: 980,
      efficiency: 83.1,
    }, {
      hashrate: 12.9,
      consumption: 1080,
      efficiency: 83.7,
    }, {
      hashrate: 13.7,
      consumption: 1180,
      efficiency: 86.1
    }, {
      hashrate: 14.2,
      consumption: 1230,
      efficiency: 86.6
    }, {
      hashrate: 15.2,
      consumption: 1330,
      efficiency: 87.5
    }, {
      hashrate: 16,
      consumption: 1430,
      efficiency: 89.4
    }, {
      hashrate: 16.8,
      consumption: 1550,
      efficiency: 92.3
    }, {
      hashrate: 17.2,
      consumption: 1630,
      efficiency: 94.8
    }, {
      hashrate: 17.6,
      consumption: 1750,
      efficiency: 99.4
    }, {
      hashrate: 18.2,
      consumption: 1780,
      efficiency: 97.8,
    }, {
      hashrate: 19.1,
      consumption: 1880,
      efficiency: 98.4,
    }],
  }, ],
  T9: [{
    model: 'Hiveon T9',
    buttons: [
      {
        title: 'T9',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/t9/Antminer-T9-all-Hiveon.tar.gz'
      },
      {
        title: 'T9',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/t9/SD-T9-Hiveon-latest.zip'
      }
    ],
    stock: {
      hashrate: 10.5,
      consumption: 1300,
      efficiency: 123.8,
    },
    table: [{
      hashrate: 7.3,
      consumption: 780,
      efficiency: 106.8
    }, {
      hashrate: 8.3,
      consumption: 880,
      efficiency: 106.0
    }, {
      hashrate: 10.6,
      consumption: 1170,
      efficiency: 110.4
    }, {
      hashrate: 11.6,
      consumption: 1200,
      efficiency: 103.4
    }, {
      hashrate: 12.4,
      consumption: 1280,
      efficiency: 103.2
    }, {
      hashrate: 13.5,
      consumption: 1410,
      efficiency: 104.4
    }, {
      hashrate: 14.3,
      consumption: 1540,
      efficiency: 107.7
    }, {
      hashrate: 15.1,
      consumption: 1640,
      efficiency: 108.6
    }, {
      hashrate: 16.1,
      consumption: 1810,
      efficiency: 112.4
    }],
  }, ],
  L3: [{
    model: 'Hiveon L3',
    buttons: [
      {
        title: 'L3',
        name: 'Web upgrade package (.tar.gz)',
        href: 'https://download.hiveos.farm/asic/L3+/Antminer-L3Plus-Hiveon-latest.tar.gz'
      },
      {
        title: 'L3',
        name: 'SD Card content (.zip)',
        href: 'https://download.hiveos.farm/asic/L3%2B/SD-L3%2B-Hiveon-latest.zip'
      },
    ],
    stock: {
      hashrate: 580,
      consumption: 800,
      efficiency: 1.3,
    },
    table: [{
      hashrate: 504,
      consumption: 660,
      efficiency: 1.3,
    }, {
      hashrate: 560,
      consumption: 740,
      efficiency: 1.3
    }, {
      hashrate: 610,
      consumption: 890,
      efficiency: 1.5
    }, {
      hashrate: 645,
      consumption: 1040,
      efficiency: 1.6
    }, {
      hashrate: 680,
      consumption: 1160,
      efficiency: 1.7
    }, {
      hashrate: 720,
      consumption: 1300,
      efficiency: 1.8
    }],
  }]
}

const modelsMap = {
  'hiveos': hiveModels,
  'bigminer': bigminerModels,
}

export default modelsMap[wlKey] || hiveModels;
