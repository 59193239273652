const PATHES_REDIRECTING = [
  {
    from: ['/asicvvs/Asicvvs/', '/asicvvs/Asicvvs'],
    to: '/asicvvs/'
  },
  {
    from: ['/changelog/changelog/', '/changelog/changelog'],
    to: '/changelog/'
  },
  {
    from: ['/contact/contact/', '/contact/contact'],
    to: '/contact/'
  },
  {
    from: ['/features/features/', '/features/features'],
    to: '/features/'
  },
  {
    from: ['/404/404/', '/404/404'],
    to: '/404/'
  },
  {
    from: ['/install/install/', '/install/install'],
    to: '/install/'
  },
  {
    from: ['/pricing/pricing/', '/pricing/pricing'],
    to: '/pricing/'
  },
  {
    from: ['/privacy/Privacy/', '/privacy/Privacy'],
    to: '/privacy/'
  },
  {
    from: ['/statistics/Statistics/', '/statistics/Statistics'],
    to: '/statistics/'
  },
  {
    from: ['/terms/Terms/', '/terms/Terms'],
    to: '/terms/'
  },
  {
    from: ['/getting_started/'],
    to: '/knowledge-base/getting_started/'
  },
  {
    from: ['/ru/getting_started/'],
    to: '/ru/knowledge-base/getting_started/'
  },
  {
    from: ['/zh/getting_started/'],
    to: '/zh/knowledge-base/getting_started/'
  },
  {
    from: ['/guides/'],
    to: '/knowledge-base/guides/'
  },
  {
    from: ['/ru/guides/'],
    to: '/ru/knowledge-base/guides/'
  },
  {
    from: ['/zh/guides/'],
    to: '/zh/knowledge-base/guides/'
  },
  {
    from: ['/hiveon-pool/'],
    to: '/knowledge-base/hiveon-pool/'
  },
  {
    from: ['/ru/hiveon-pool/'],
    to: '/ru/knowledge-base/hiveon-pool/'
  },
  {
    from: ['/zh/hiveon-pool/'],
    to: '/zh/knowledge-base/hiveon-pool/'
  },
  {
    from: ['/hiveon-asic-firmware/'],
    to: '/knowledge-base/hiveon-asic-firmware/'
  },
  {
    from: ['/ru/hiveon-asic-firmware/'],
    to: '/ru/knowledge-base/hiveon-asic-firmware/'
  },
  {
    from: ['/zh/hiveon-asic-firmware/'],
    to: '/zh/knowledge-base/hiveon-asic-firmware/'
  },
];

module.exports = {
  PATHES_REDIRECTING,
};
