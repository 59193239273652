// extracted by mini-css-extract-plugin
export var root = "languageSwitcher-module--root--8qc8n";
export var inlineItems = "languageSwitcher-module--inlineItems--7e8t3";
export var languageItem = "languageSwitcher-module--languageItem--hd3Nd";
export var active = "languageSwitcher-module--active--1xXko";
export var languageCaption = "languageSwitcher-module--languageCaption--8W6RC";
export var languagesContainer = "languageSwitcher-module--languagesContainer--q3hQ7";
export var languages = "languageSwitcher-module--languages--+aTc2";
export var currentLanguage = "languageSwitcher-module--currentLanguage--JbLaQ";
export var languageFlag = "languageSwitcher-module--languageFlag--IqndG";
export var languageSwitch = "languageSwitcher-module--languageSwitch--IchwT";