import React, { useState, useEffect, useRef } from 'react'
import { useClickAway } from 'react-use';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _trimEnd from 'lodash/trimEnd';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
// import enFlag from 'utils/images/EN_FLAG.svg';
// import ruFlag from 'utils/images/RU_FLAG.svg';
// import zhFlag from 'utils/images/CN_FLAG.svg';
import switchLangIcon from 'utils/images/icon-switch-language.svg';
import cx from 'classnames';
import WL from 'constants/whiteLabel';
import * as s from './languageSwitcher.module.css';
import languages from 'constants/languages';
import { navigate } from 'gatsby';
import withLocation from 'helpers/withLocation';
import { injectIntl } from 'react-intl';

const ROOT_LANG = languages.EN;

const allLanguages = {
  [languages.EN]: {
    value: languages.EN,
    // icon: enFlag
  },
  [languages.RU]: {
    value: languages.RU,
    // icon: ruFlag
  },
  [languages.ZH]: {
    value: languages.ZH,
    // icon: zhFlag
  }
};

const availableLanguages = _pick(allLanguages, WL.getLangs());

const LanguageSwitcher = (props) => {
  const {
    isHide,
    isInline,
    excludedLangs = [],
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [isLanguagesOpen, setIsLanguageOpen] = useState(false);
  const [language, setLanguage] = useState(() => props.intl.locale);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setIsLanguageOpen(false);
  });

  useEffect(() => {
    setIsMounted(true);
    setLanguage(props.intl.locale);
  }, []);

  const toggleLanguages = () => {
    setIsLanguageOpen(!isLanguagesOpen);
  };

  const preparePathForLangWithHash = (lang, path) => {
    const isSlashBeforeHash = path.split('#')[0].slice(-1) === '/';
    const locale = lang === ROOT_LANG ? '' : `/${lang}`;

    if (isSlashBeforeHash) {
      const preparedPathStart = path.split('#')[0].slice(0, -1);
      const preparedPathEnd = path.split('#')[1];

      return `${locale}${preparedPathStart}/#${preparedPathEnd}`;
    } else {
      const preparedPathStart = path.split('#')[0];
      const preparedPathEnd = path.split('#')[1];

      return `${locale}${preparedPathStart}/#${preparedPathEnd}`;
    }
  }

  const removeLangFromPathWithHash = (oldLang, path) => {
    const isSlashBeforeHash = path.split('#')[0].slice(-1) === "/";
    if (isSlashBeforeHash) {
      const preparedPathStart = path.split('#')[0].slice(0, -1).replace(`/${oldLang}`, "");
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}/#${preparedPathEnd}`
    } else {
      const preparedPathStart = path.split('#')[0].replace(`/${oldLang}`, "");
      const preparedPathEnd = path.split('#')[1];

      return `${preparedPathStart}#${preparedPathEnd}`
    }
  }

  const preparePathWithHash = (oldLang, newLang, path) => {
    const res = removeLangFromPathWithHash(oldLang, path);
    return preparePathForLangWithHash(newLang, res);
  }

  const isRoot = pathname => {
    if (pathname === '/' || pathname === '') {
      return true;
    }

    const values = Object.values(_omit(languages, [ROOT_LANG]));

    const variants = _reduce(
      values,
      (res, val) => _concat(res, [`/${val}`, `/${val}/`]),
      []
    );

    if (_includes(variants, pathname)) {
      return true;
    }

    return false;
  }

  const handleLanguageChange = newLanguage => {
    const { location } = props;

    // do nothing if incoming language equal state language
    if (newLanguage === language) return;

    if (location.hash) {
      navigate(
        preparePathWithHash(
          language, newLanguage, `${location.pathname}${location.hash}`
        )
      );

      setIsLanguageOpen(false);
      setLanguage(newLanguage);
      return;
    }

    if (isRoot(location.pathname)) {
      const newLang = newLanguage === ROOT_LANG ? '' : newLanguage;
      !newLang ? navigate(`/${newLang}`) : navigate(`/${newLang}/`);
      return;
    }

    let preparedPath = _trimEnd(location.pathname, '/');

    if (
      preparedPath.startsWith(`/${language}/`)
    ) {
      preparedPath = preparedPath.replace(`/${language}`, '');
    }

    if (newLanguage !== ROOT_LANG) {
      preparedPath = `/${newLanguage}${preparedPath}/`;
    } else {
      preparedPath = `${preparedPath}/`;
    }
    
    if(location.pathname.match('changelog')) {
      preparedPath = `${preparedPath}${location.search}`;
    }

    setIsLanguageOpen(false);
    navigate(preparedPath);
  };

  const hideSwitcher = Object.keys(availableLanguages).length === excludedLangs.length;

  if (isHide || hideSwitcher || !isMounted) return null;

  const selectedLanguage = _get(availableLanguages, props.intl.locale);
  
  if (isInline) {
    return (
      <div className={s.inlineItems}>
        {_map(Object.values(availableLanguages), lang => (
          <div
            key={lang.value}
            className={cx(s.languageItem, {
              [s.active]: selectedLanguage.value == lang.value,
            })}
            onClick={() => handleLanguageChange(lang.value)}
          >
            {/* <img src={lang.icon} alt="language" className={s.languageFlag} /> */}
            <span className={s.languageCaption}>{lang.value.toUpperCase()}</span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={s.root} ref={ref}>
      <div
        className={s.currentLanguage}
        onClick={toggleLanguages}
      >
        {/* <img
          src={selectedLanguage.icon}
          alt="language"
          className={s.languageFlag}
        /> */}
        <img src={switchLangIcon} alt="lang" className={s.languageSwitch} />
        <span className={s.languageCaption}>
          {selectedLanguage.value.toUpperCase()}
        </span>
      </div>

      {isLanguagesOpen && (
        <div className={s.languagesContainer}>
          <ul className={s.languages}>
            {_map(Object.values(_omit(availableLanguages, [selectedLanguage.value, ...excludedLangs])), lang => (
              <li
                key={lang.value}
                className={s.languageItem}
                onClick={() => handleLanguageChange(lang.value)}
              >
                {/* <img src={lang.icon} alt="language" className={s.languageFlag} /> */}
                <span className={s.languageCaption}>{lang.value.toUpperCase()}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default injectIntl(withLocation(LanguageSwitcher));
