    import Typography from 'typography'


    const typography = new Typography({
        googleFonts: [{
            name: 'Open Sans',
            styles: ['400', '500', '600', '700&display=swap']
        }],
        bodyColor: '#D0D4D7',
        baseFontSize: '16px',
        baseLineHeight: 1.7,
        headerFontFamily: [
            'Gilroy',
            'sans-serif'
        ],
        bodyFontFamily: ['Open Sans', 'sans-serif'],
        overrideStyles: () => ({
            a: {
                fontWeight: 600,
                color: 'var(--hiveon-white)',
                textDecoration: 'none'
            },
            h1: {
                fontSize: '54px',
                fontWeight: 900,
                lineHeight: '54px',
                color: 'var(--hiveon-gray)'
            },
            h2: {
                fontSize: '54px',
                fontWeight: 700,
                color: 'var(--hiveon-white)',
                marginBottom: '10px'

            },
            h3: {
                fontSize: '28px',
                lineHeight: '28px',
                color: 'var(--hiveon-gray)',
                marginBottom: 0
            },
            h4: {
                fontSize: '24px',
                fontWeight: 400,
                marginBottom: '50px'
            }
        })

    })

    if (process.env.NODE_ENV !== "production") {
        typography.injectStyles()
    }
    export default typography
