const LANGS = require('../../src/constants/locales');
const languagesRe = new RegExp(`_(${LANGS.join('|')})/?$`);

const removeLangFromSlug = (slug = '') => {
  return slug.replace(languagesRe, '');
};

const getCategoryPath = (categoriesTree = [], level = 5, locale = null) => {
	if (!categoriesTree[level-1]) { return null }
	let categories = [];
	categoriesTree.forEach((category, index) => {
		if (index <= level -1) {
			categories.push(removeLangFromSlug(category.slug));
		}
	});
	return `${locale !== 'en' ? `/${locale}` : ''}/knowledge-base/${categories.join('/')}/`;
};

const getFullPath = (categoriesTree = [], slug, locale = null) => {
	const categories = categoriesTree.map((category) => removeLangFromSlug(category.slug)).join('/');
	const articleSlugDefault = removeLangFromSlug(slug);
	return `${locale !== 'en' ? `/${locale}` : ''}/knowledge-base/${categories}/${articleSlugDefault}/`;
};

const preparePathWithSlash = (path = '') => {
  const isPathAlreadyWithSlash = path.slice(-1) === '/';
  const isPathWithHash = path.includes('#');
  if (isPathAlreadyWithSlash || isPathWithHash) {
    return path;
  }
  return `${path}/`;
}

module.exports = {
	getCategoryPath,
	getFullPath,
  removeLangFromSlug,
  preparePathWithSlash,
}