import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { REF_ID } from 'constants/index';
import { KEYCLOAK_REDIRECT_URI } from 'constants/env';
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

const useAuth = () => {
  const [refId, setRefId] = useState('');

  useEffect(() => {
    const refId = Cookies.get(REF_ID);
    setRefId(refId);
  }, []);

  const intl = useIntl();

  return {
    getRegistraionURLWithParams: (registrationURL = '', localRefId = '') => {
      const urlParams = queryString.stringify({
        ref: localRefId || refId,
        ui_locales: intl.locale,
        redirect_uri: KEYCLOAK_REDIRECT_URI,
      });
      const regUrl = `${registrationURL}${_isEmpty(urlParams) ? '' : '&' + urlParams}`;
      return regUrl;
    },
  };
};

export default useAuth;
