export const HOW_TO_ADD_ASIC_LINK = 'https://medium.com/hiveon/hiveon-asic-new-users-initial-installation-and-setup-manual-b35cc95e2292';
export const LEARN_ASIC_LINK = 'https://medium.com/hiveon/hiveon-asic-existing-users-upgrade-and-setup-manual-bc62a3ea8baf';
export const STATIC_SERVER = 'https://lbd.hiveos.farm/hiveon-site/static';
export const KB_OG_IMG = 'https://lbd.hiveos.farm/hiveon-site/static/kb.png';
export const KB_OG_IMG_RU = 'https://lbd.hiveos.farm/hiveon-site/static/kb_ru.png';
export const MAIN_IMG = 'https://lbd.hiveos.farm/hiveon-site/static/main.png';

export const VERSIONS_URL = 'https://api2.hiveos.farm/api/v2/hive/versions';
export const REF_ID = 'id_referral';

export const LINK_TYPES = {
  url: 'LINK_TYPES.url',
  email: 'LINK_TYPES.email',
};