import 'whatwg-fetch';
import 'utils/object.isInfinite.polyfill.js';
import 'es6-symbol/polyfill';
import {removeLangFromSlug } from 'utils/slug-builder';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie'
import { StaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TypographyStyle, GoogleFont } from 'react-typography';
import cx from 'classnames';
import NotSupportedBrowser from 'components/NotSupportedBrowser';
import LanguageSwitcher from 'components/LanguageSwitcher';
import ErrorBoundary from 'components/ErrorBoundary';
import favicons from 'utils/favicons';
import typography from 'utils/typography';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _toLower from 'lodash/toLower';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import _endsWith from 'lodash/endsWith';
import _trimEnd from 'lodash/trimEnd';
import _trim from 'lodash/trim';
import _omit from 'lodash/omit';
import _some from 'lodash/some';
import * as styles from './layout.module.css';
import meta from 'constants/meta.js';
import WL from 'constants/whiteLabel';
import Intercom from 'components/Intercom';
import Link from 'components/Link';
import { STATIC_SERVER } from 'constants/index';
import asicModels from 'constants/asicModels';
import { PATHES_REDIRECTING } from 'constants/redirects';
import withLocation from 'helpers/withLocation';
import { preparePathWithSlash } from 'helpers/index';
import logoImg from 'utils/images/hiveon-logo2.svg';
import languages from '../constants/languages';
import useAuth from 'hooks/useAuth';
import ApiContext from 'context/ApiContext';
import { GDPR_GA } from 'constants/cookieGdpr';
import queryString from 'query-string';
import {
  UiContext,
  Header,
  DOMAIN_TYPES,
  ROUTE_TYPES,
  MENU_DATA,
} from '@hiveon/modules';
import '@hiveon/modules/build/main.css';
import jsonLd from '../constants/jsonLd';
import './layout.css';
import '../styles/app.scss';

const ASIC_MAIN_PAGE = 'asic';
const ASIC_HUB_PAGE = 'ASICHub';
const REFERRAL_PAGE = 'referral';
const domainType = DOMAIN_TYPES.hiveon;

const ASIC_PAGES = [
  ..._map(Object.values(asicModels), model => _get(model, 'key', '').toLowerCase()),
  ASIC_MAIN_PAGE,
]

const getPageMetaWithLocales = (meta, intlFormat) => {
  if (!intlFormat) return meta;

  const preparedPageMeta = {
    ...meta,
    title: intlFormat(meta.title),
    meta: meta.meta.map(item => {
      if (item.name === 'description' || item.property === 'og:description') {
        return { ...item, content: intlFormat(item.content) }
      }
      if (item.name === 'twitter:description' || item.property === 'twitter:description') {
        return { ...item, content: intlFormat(item.content) }
      }
      if (item.name === 'og:title' || item.property === 'og:title') {
        return { ...item, content: intlFormat(item.content) }
      }
      return item;
    })
  };

  return preparedPageMeta;
}

/* eslint-disable-next-line */
const getDesc = (page, customMeta, lang = 'en', intlFormat) => {
  const metaData = meta(lang)[page];

  if (
    (page !== null && (!metaData)) ||
    (page === null && !customMeta)
  ) {
    throw new Error(`No page meta. Page: ${page}.`);
  }

  let resMeta = customMeta;

  if (!customMeta && metaData) {
    resMeta = getPageMetaWithLocales(metaData, intlFormat);
  }

  const metaArr = _get(resMeta, 'meta', []);
  const appName = _find(metaArr, ({ name }) => name === 'application-name');
  const themeColor = _find(metaArr, ({ name }) => name === 'theme-color');
  const description = _find(metaArr, ({ name }) => name === 'description');

  if (
    appName &&
    !_find(metaArr, ({ name }) => name === 'apple-mobile-web-app-title')
  ) {
    resMeta.meta.push({
      name: 'apple-mobile-web-app-title',
      content: appName.content
    });
  }

  if (
    themeColor &&
    !_find(metaArr, ({ name }) => name === 'msapplication-TileColor')
  ) {
    resMeta.meta.push({
      name: 'msapplication-TileColor',
      content: themeColor.content,
    });
  }

  if (
    description &&
    !_find(metaArr, ({ property }) => property === 'og:site_name')
  ) {
    resMeta.meta.push({
      property: 'og:site_name',
      content: 'Hive OS',
    });
  }

  if (
    description &&
    !_find(metaArr, ({ property }) => property === 'og:description')
  ) {
    resMeta.meta.push({
      property: 'og:description',
      content: description.content,
    });
  }

  if (
    !_find(metaArr, ({ name }) => name === 'twitter:image') &&
    !_find(metaArr, ({ property }) => property === 'og:image')
  ) {
    resMeta.meta.push({
      property: 'og:image',
      content: `${STATIC_SERVER}/hiveon_main_en.jpg`,
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:card')) {
    resMeta.meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:title')) {
    resMeta.meta.push({
      name: 'twitter:title',
      content: 'Hive OS',
    });
  }

  if (description &&
    !_find(metaArr, ({ name }) => name === 'twitter:description')) {
    resMeta.meta.push({
      name: 'twitter:description',
      content: description.content,
    });
  }

  if (!_find(metaArr, ({ name }) => name === 'twitter:site')) {
    resMeta.meta.push({
      name: 'twitter:site',
      content: '@hiveos',
    });
  }

  return resMeta;
};

const getTitle = (page, customMeta, lang = 'en', intlFormat) => {
  const desc = getDesc(page, customMeta, lang, intlFormat);
  let title = _get(desc, 'title', '');
  let postfix = _get(desc, 'postfix', false);

  if (postfix) {
    title += ' | ' + postfix;
  }

  return title;
};

const getMeta = (page, customMeta, lang = 'en', intlFormat) => {
  const desc = getDesc(page, customMeta, lang, intlFormat);

  return _get(desc, 'meta', []);
};

const getLinkCanonicalLangString = (language, pageName, page) => {
  // check if english, or pageName is a path with lang in the end, so should return ''
  if (language === 'en' || _endsWith(pageName, `_${language}/`)) {
    return '';
  }
  if (language !== 'en') {
    // check root page
    if (page === 'default' && !pageName) {
      return `${language}/`;
    }
    return `${language}/`
  }
}

const getLinkCanonical = (location, page, params) => {
  const origin = 'https://hiveon.com/';
  // add second pageName param, cause page prop used in meta info
  const pageName = _get(params, 'pageName');
  // set lang to prepare canonical link
  const lang = getLinkCanonicalLangString(_get(params, 'lang'), pageName, page);
  // check is page on hardcoded redirect path
  const foundedRedirect = _find(PATHES_REDIRECTING, ({ from = [] }) => from.includes(location.pathname));
  if (foundedRedirect) {
    return <link rel="canonical" href={`${origin}${lang}${foundedRedirect.to.slice(0, -1)}`}/>
  }
  // check asic models pages
  const isAsicPage = _get(params, 'isAsicPage');
  if (isAsicPage) {
    return <link rel="canonical" href={`${origin}${lang}asic/`}/>
  }

  if (page === 'blog' && pageName) {
    return <link rel="canonical" href={`${origin}${lang}blog${removeLangFromSlug(pageName)}/`}/>
  }

  if (pageName) {
    let name = pageName;
    if (_startsWith(name, '/')) {
      name = name.slice(1)
    }
    if (_endsWith(name, '/')) {
      name = name.slice(0, -1)
    }
    return <link rel="canonical" href={`${origin}${lang}${name}/`}/>
  }

  if (page === 'default') {
    return <link rel="canonical" href={`${origin}${lang}`}/>
  }

  if (page) {
    return <link rel="canonical" href={`${origin}${lang}${_toLower(page)}/`}/>
  }

  return null;
}

export const ROUTE_TYPES_MAP = {
  hiveos: '/',
  asic: 'asic',
  asichub: 'asichub',
};

const checkRoute = (route, pathname) => {
  return new RegExp('^' + route + '/?$').test(pathname);
};

const getActiveHeaderId = (pathname, hash, lang, domainType) => {
  const trimmedPathname = lang === 'en' ? _trimEnd(pathname, '/') : `/${_trim(pathname, `/${lang}`)}`;
  const fullPathname = `${trimmedPathname}/${hash}`;

  const activeId = Object.keys(ROUTE_TYPES).find(p => {
    const routeData = MENU_DATA.find(r => r.id === p && r.domainType === domainType);

    if (!routeData) {
      return false;
    }

    const routeBase = _get(routeData, 'url', '');
    const routeSub = _get(routeData, 'sub.items', []);

    const isMatchSubroute = !!routeSub.find(r => {
      return checkRoute(r.url, fullPathname) || checkRoute(r.url, trimmedPathname)
    }) || checkRoute(routeBase, fullPathname) || checkRoute(routeBase, trimmedPathname);

    return fullPathname === '/' && p === 'hiveos'
      ? true
      : isMatchSubroute;
  });
  return activeId || '';
}

const getLinkHrefLang = (location, excludedLangs = []) => {
  const origin = 'https://hiveon.com';
  const pathname = removeLangFromSlug(_get(location, 'pathname'));

  if (!pathname) {
    return null;
  }

  const languagesArray = Object.values(_omit(languages, 'EN'));

  const isLangPage = _some(languagesArray, lang => _endsWith(_trimEnd(pathname, '/'), `_${lang}`));
  const isLangRootPage = _some(languagesArray, lang => _endsWith(_trimEnd(pathname, '/'), `/${lang}`));
  const isRootPage = pathname === '/';

  let resultPath = isRootPage ? pathname : _trimEnd(pathname, '/');

  if (isLangRootPage) {
    resultPath = _trimEnd(pathname, '/').slice(0, -2);
  }

  if (isLangPage) {
    resultPath = _trimEnd(pathname, '/').slice(0, -3);
  }


  const hreflangs = {
    [languages.EN]: <link 
        key={languages.EN} 
        rel="alternate" 
        hrefLang={languages.EN} 
        href={`${origin}${_get(preparePathWithSlash(resultPath), 'length') < 2 ? '' : preparePathWithSlash(resultPath)}`} 
      />,
  };
  
  if (isLangRootPage || isRootPage) {
    languagesArray.forEach(lang => {
      hreflangs[lang] = <link key={lang} rel="alternate" hrefLang={lang} href={`${origin}/${lang}${preparePathWithSlash(resultPath)}`} />
    });
  } else {
    languagesArray.forEach(lang => {
      hreflangs[lang] = <link key={lang} rel="alternate" hrefLang={lang} href={`${origin}/${lang}${preparePathWithSlash(resultPath)}`} />
    });
  }

  const resultHrefLangs = _omit(hreflangs, excludedLangs)

  return Object.values(resultHrefLangs);
}

const getJsonLdSchemasBy = (path, locale) => {
  const trimmedSlashPath = _trimEnd(path, '/');
  const trimmedLangPath = _trimEnd(trimmedSlashPath, `_${locale}`);

  const allPages = _get(jsonLd, ["", locale], [])
  const pageByPath = _get(jsonLd, [trimmedLangPath, locale], [])

  return [...allPages, ...pageByPath]
}

const interceptClickEvent = (e) => {
  let href;
  const target = e.target || e.srcElement;
  const linkEl = target.closest('A');

  if (linkEl) {
    href = linkEl.getAttribute('href') || '';
    const shouldTrack = href.includes('id.hiveon.com');

    if (shouldTrack) {
      const parsedUri = queryString.parseUrl(href);
      const hashIdx = href.indexOf('#');
      const hash = ~hashIdx ? href.substr(hashIdx) : '';

      // Modify url only if have hash
      if (hash) {
        e.stopPropagation();
        e.preventDefault();

        const redirectUriWithHash = encodeURIComponent(`${_get(parsedUri, 'query.redirect_uri', '')}/${hash}`);
        const modifiedUrl = `${parsedUri.url}?${queryString.stringify(_omit(parsedUri.query, ['redirect_uri']))}&redirect_uri=${redirectUriWithHash}`;

        window.location.href = modifiedUrl;
      }
    }
  }
}

const Layout = ({
  children,
  customMeta,
  withHeaderWrapper = true,
  withHeader = true,
  withIntercom = true,
  page = 'default',
  lang = 'en',
  intl,
  extraTitle,
  location,
  isAsicPage,
  pageName,
  excludedLangs,
  isHome = false,
  isDarkBg = false,
  registrationURL = '',
  isCookieGaSet,
}) => {
  const [localRefId, setLocalRefId] = useState('');
  const { getRegistraionURLWithParams } = useAuth();
  const jsonLdSchemas = getJsonLdSchemasBy(_get(location, 'pathname'), lang);

  useEffect(() => {
    if ( location.search ) {
      const REF_ID = 'id_referral';
      const ref = new URLSearchParams(location.search).get('ref');
      const isRefExistAndNotSet = ref !== null && Cookies.get(REF_ID) === undefined;
      const hostname = _get(location, 'hostname', '');
      const cookieDomain = hostname === 'localhost' ? hostname : `.${hostname}`;

      if (isRefExistAndNotSet) {
        Cookies.set('id_referral', ref, {expires: 365, path: '/', domain: cookieDomain});
        setLocalRefId(ref);
      }
    }
  }, []);

  useEffect(() => {
    // Init global tracking listener
    if (isCookieGaSet || !!Cookies.get(GDPR_GA)) {
      if (document.addEventListener) {
        document.addEventListener('click', interceptClickEvent);
      }
    }
  }, [isCookieGaSet]);

  return (
    <UiContext.Provider
      value={{
        intl,
        FormattedMessage,
        LinkComponent: Link,
        baseUrls: {
          [DOMAIN_TYPES.hiveon]: 'https://hiveon.com',
          [DOMAIN_TYPES.pool]: 'https://multisite.hiveon.dev',
          [DOMAIN_TYPES.forum]: 'https://hiveon.com/forum',
          [DOMAIN_TYPES.manage]: 'https://the.hiveos.farm',
        },
        domainType,
        registerLink: 'https://the.hiveos.farm/register',
        loginLink: getRegistraionURLWithParams(registrationURL).replace('registrations', 'auth'),
      }}
    >
      <NotSupportedBrowser>
        <StaticQuery
          query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
          render={() => (
            <>
              <Helmet
                title={getTitle(page, customMeta, lang, intl.formatMessage)}
                meta={getMeta(page, customMeta, lang, intl.formatMessage)}
                htmlAttributes={{
                  lang
                }}
                bodyAttributes={{
                  class: isHome ? 'bg-dark-only' : '',
                }}
              >
                <TypographyStyle typography={typography}/>
                <GoogleFont typography={typography}/>

                <link rel="icon" href={favicons.favicon} type="image/x-icon"/>
                <link rel="apple-touch-icon" href={favicons.appleTouchIcon}/>
                <link rel="shortcut icon" href={favicons.appleTouchIcon}/>
                <link type="image/x-icon" rel="icon" href={favicons.favicon32}/>
                <link rel="safari-pinned-tab" href={favicons.safariPinnedTab}/>
                <link rel="mask-icon" href={favicons.safariPinnedTab}/>
                <link rel="icon" type="image/png" sizes="32x32" href={favicons.favicon32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={favicons.favicon16}/>
                {WL.withLinkCanonical && getLinkCanonical(location, page, { isAsicPage, pageName, lang })}
                {WL.withLinkHrefLang && getLinkHrefLang(location, excludedLangs)}
                {_map(jsonLdSchemas, (schema, idx) => {
                  return (
                    <script key={idx} type="application/ld+json">
                      {schema}
                    </script>
                  );
                })}

              </Helmet>
              <ApiContext.Provider value={{ registrationURL, localRefId }}>
                {
                  withHeader
                    ? (
                      <Header
                        className={styles.headerWrap}
                        subClassName={styles.headerSubWrap}
                        mobClassName={styles.headerMobWrap}
                        activeId={getActiveHeaderId(location.pathname, location.hash, lang, domainType)}
                        route={`https://hiveon.com${location.pathname}`}
                        logoImg={logoImg}
                        LanguageSwitcher={LanguageSwitcher}
                        excludedLangs={excludedLangs}
                      />
                    )
                    : null
                }
                <div
                  className={cx(styles.htmlWrap, {
                    'bg-dark-alt' : isHome || isDarkBg
                  })}
                  style={{
                    margin: "0 auto",
                    paddingTop: 0,
                    overflow: "hidden"
                  }}
                >
                  {!isHome || !isDarkBg &&
                  <div className={cx(styles.intro, {
                    [styles.asicIntro]: ASIC_PAGES.includes(page),
                    [styles.asicHubIntro]: page === ASIC_HUB_PAGE,
                    [styles.referral]: page === REFERRAL_PAGE,
                  })}></div>
                  }
                  <ErrorBoundary>
                    {children}
                  </ErrorBoundary>
                </div>
              </ApiContext.Provider>
            </>
          )}
        />
        { withIntercom ? (<Intercom />) : null }
      </NotSupportedBrowser>
    </UiContext.Provider>
  );
};

Layout.propTypes = {
  page: PropTypes.string,
  extraTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  isAsicPage: PropTypes.bool,
  withHeaderWrapper: PropTypes.bool,
  pageName: PropTypes.string,
};

Layout.defaultProps = {
  page: 'default',
  isAsicPage: false,
  pageName: null,
}

const mapStateToProps = ({ root }) => ({
  isCookieGaSet: root.isCookieGaSet,
})

export default connect(mapStateToProps, null)(injectIntl(withLocation(Layout)));
